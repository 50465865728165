import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import swal from 'sweetalert2'
import axios from 'axios'
import dayjs from 'dayjs'
import Switch from 'react-switch'
import 'tippy.js/dist/tippy.css'
import 'react-rangeslider/lib/index.css'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import Swal from 'sweetalert2'
import {AlertModal} from '../../CommonFunctions/CommonFunction'
import RowWithSlider from './RowWithSlider'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {getTimeZone} from '../../modules/auth/redux/AuthCRUD'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'

const API = process.env.REACT_APP_API_URL

// const configschema = Yup.object().shape({
//   screenshot_Interval: Yup.number()
//     .typeError('Please enter a numeric value only')
//     .integer('Please enter a numeric value only')
//     .required('Field is required'),

//   sessionExpiryDay: Yup.string()
//     .matches(numbers, 'Please enter a numeric value only')
//     .required('Field is required')
//     .max(4, 'Maximum 4 digits'),
//   idle_Time_Interval: Yup.number()
//     .typeError('Please enter a numeric value only')
//     .integer('Please enter a numeric value only')
//     .required('Field is required')
//     .min(1, 'Minimum 1 minute')
//     .max(100, 'Maximum 100 minute'),
//   screenCaptureActivityInterval: Yup.number()
//     .typeError('Please enter a numeric value only')
//     .integer('Please enter a numeric value only')
//     .required('Field is required')
//     .min(1, 'Minimum 1 minute')
//     .max(100, 'Maximum 100 minute'),
//   idleTimeAlert: Yup.number()
//     .typeError('Please enter a numeric value only')
//     .integer('Please enter a numeric value only')
//     .required('Field is required')
//     .max(100, 'Maximum 100 minute'),
//   suspiciousKeyboardAlert: Yup.number()
//     .typeError('Please enter a numeric value only')
//     .integer('Please enter a numeric value only')
//     .required('Field is required')
//     .max(100, 'Maximum 100 minute'),
//   cutOffTime: Yup.string().required('Field is required'),
//   noWorkingDays: Yup.string()
//     .matches(numbers, 'Please enter a numeric value only')
//     .required('Field is required'),
//   noWorkingHours: Yup.string().required('Field is required'),
// })

const SystemConfig: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [timeZone, setTimeZone] = useState<any[]>([])
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>([])
  const [alignment, setAlignment] = useState<any>('System')

  const initialValues = {
    blobUploadInterval: '',
    dbDataUploadInterval: '',
    updateEndTimeInterval: '',
    screenshot_Interval: '',
    idle_Time_Interval: '',
    captureScreenFileWriteInterval: '',
    sessionExpiryDay: 0,
    cutOffTime: '',
    screenCaptureActivityInterval: '',
    projectTaskConfig: false,
    noWorkingHours: '',
    workingDays: [],
    idleTimeAlert: 0,
    suspiciousKeyboardAlert: 0,
    captureKeyboardMouseStroke: 0,
    screenshotBlurEffect: '',
    idleTimeFromSec: 0,
    isSmartIdleEnable: false,
    isEnableScreenshotBlurEffect: false,
    isEnableLiveStreaming: false,
    isEnableLiveRecording: false,
    isEnableManualTracking: false,
    recordingFramesDurationSeconds: 0,
    recordingCaptureDurationMinutes: 0,
    timeZoneId: '',
    isStartStopMailAlert: false,
    isSuspiciousKeyboardMailAlert: false,
    isIdleMailAlert: false,
    activeUserDurationMinutes: 0,
    emailRetryCount: 0,
    gracePeriod: 0,
    trialDays: 0,
    validActivityInterval: 0,
    validAwayInterval: 0,
  }

  const formik = useFormik({
    initialValues,
    //validationSchema: configschema,
    onSubmit: (values) => {
      setLoading(true)
      // values.cutOffTime = values.cutOffTime.replaceAll(':', '.')
      // values.noWorkingHours = values.noWorkingHours.replaceAll(':', '.')

      AlertModal(
        'Are you sure?',
        'It Will Change Configuration',
        'warning',
        true,
        '#3085d6',
        'Update'
      ).then((result: any) => {
        if (result.isConfirmed) {
          values.validAwayInterval = values.validAwayInterval * 60
          values.validActivityInterval = Number(values.validActivityInterval)

          console.log('The values re ', values)
          axios
            .post(`${API}/SystemConfiguration/UpdateSystemConfiguration`, values)
            .then((res) => {
              AlertModal(
                res.data.message,
                '',
                res.data.success ? 'success' : 'warning',
                false,
                '#7066E0',
                'Ok'
              )

              callconfigdetail()
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
        } else if (result.dismiss === swal.DismissReason.cancel) {
          AlertModal('Nothing Has Been Changed', '', 'error', false, '#7066E0', 'Ok')
          callconfigdetail()
          setLoading(false)
        }
      })
    },
  })

  const callconfigdetail = () => {
    axios
      .get(`${API}/SystemConfiguration/GetSystemConfiguration`)
      .then((res) => {
        for (const keys in res.data) {
          if (keys === 'timeZoneName') {
            setSelectedTimeZone({
              label: res.data.timeZoneName,
              value: res.data.timeZoneId,
            })
          } else if (keys === 'cutOffTime' || keys === 'noWorkingHours') {
            const value = res.data[keys]
            formik.setFieldValue(`${keys}`, res.data[keys].replaceAll('.', ':'))
          } else if (keys === 'validAwayInterval') {
            // Convert seconds to minutes before setting the value
            const minutes = Math.floor(res.data[keys] / 60)
            formik.setFieldValue(`${keys}`, minutes)
          } else {
            formik.setFieldValue(`${keys}`, res.data[keys])
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    callconfigdetail()

    getTimeZone()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.timeZonesName,
            value: item.id,
          }
        })
        setTimeZone(newList)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (Swal.isVisible() && !Swal.getPopup()?.contains(event.target)) {
        Swal.close()
        setLoading(false)
      }
    }

    document?.addEventListener('click', handleOutsideClick)

    return () => {
      document?.removeEventListener('click', handleOutsideClick)
    }
  })

  const changeFieldValueHandle = (name: string, value: any) => {
    formik.setFieldValue(name, String(value))
  }

  const handleCheckboxChange = (day: string) => {
    const updatedWorkingDays: string[] = [...formik.values.workingDays]
    const isChecked =
      formik.values?.workingDays?.length > 0 ? updatedWorkingDays.includes(day) : false
    if (isChecked) {
      const index = updatedWorkingDays.indexOf(day)
      updatedWorkingDays.splice(index, 1)
    } else {
      updatedWorkingDays.push(day)
    }

    formik.setFieldValue('workingDays', updatedWorkingDays)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 align-items-center'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Configuration</h3>
        </div>
        <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
          <ToggleButtonGroup
            color='primary'
            value={alignment}
            exclusive
            onChange={(e: any) => {
              setAlignment(e.target.value)
              formik.resetForm()
              callconfigdetail()
            }}
            aria-label='Platform'
          >
            <ToggleButton value='System'>System</ToggleButton>
            <ToggleButton value='OrgSAdmin'>Organization (Super Admin)</ToggleButton>
            <ToggleButton value='OrgAdmin'>Organization (Admin)</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            {alignment === 'System' && (
              <>
                <RowWithSlider
                  fieldName='blobUploadInterval'
                  title='Blob Upload Interval (sec)'
                  value={formik.values.blobUploadInterval}
                  updateValue={changeFieldValueHandle}
                  min={300}
                  max={3600}
                />

                <RowWithSlider
                  fieldName='dbDataUploadInterval'
                  title='DB Data Upload Interval (sec)'
                  value={formik.values.dbDataUploadInterval}
                  updateValue={changeFieldValueHandle}
                  min={60}
                  max={3600}
                />
                <RowWithSlider
                  fieldName='updateEndTimeInterval'
                  title='Update End Time Interval (sec)'
                  value={formik.values.updateEndTimeInterval}
                  updateValue={changeFieldValueHandle}
                  min={60}
                  max={600}
                />
                <RowWithSlider
                  fieldName='captureScreenFileWriteInterval'
                  title='Capture Screen File Write Interval (sec)'
                  value={formik.values.captureScreenFileWriteInterval}
                  updateValue={changeFieldValueHandle}
                  min={120}
                  max={300}
                />

                <RowWithSlider
                  fieldName='screenCaptureActivityInterval'
                  title='Screen Capture Activity Interval (sec)'
                  value={formik.values.screenCaptureActivityInterval}
                  updateValue={changeFieldValueHandle}
                  min={10}
                  max={20}
                />
                <RowWithSlider
                  fieldName='emailRetryCount'
                  title='Email Retry Count'
                  value={formik.values.emailRetryCount}
                  updateValue={changeFieldValueHandle}
                  min={3}
                  max={20}
                />

                <RowWithSlider
                  fieldName='gracePeriod'
                  title='Grace Period (days)'
                  value={formik.values.gracePeriod}
                  updateValue={changeFieldValueHandle}
                  min={7}
                  max={30}
                />

                <RowWithSlider
                  fieldName='trialDays'
                  title='Trial Days (days)'
                  value={formik.values.trialDays}
                  updateValue={changeFieldValueHandle}
                  min={7}
                  max={30}
                />
                <RowWithSlider
                  fieldName='validActivityInterval'
                  title='Valid Activity Interval (sec)'
                  value={formik.values.validActivityInterval}
                  updateValue={changeFieldValueHandle}
                  min={30}
                  max={300}
                  steps={10}
                />
                <RowWithSlider
                  fieldName='validAwayInterval'
                  title='Valid Away Interval (min)'
                  value={formik.values.validAwayInterval}
                  updateValue={changeFieldValueHandle}
                  min={1}
                  max={20}
                />
              </>
            )}

            {alignment === 'OrgSAdmin' && (
              <>
                {/* <RowWithSlider
                  fieldName='idleTimeFromSec'
                  title='Smart Idle Time (sec)'
                  value={formik.values.idleTimeFromSec}
                  updateValue={changeFieldValueHandle}
                  min={5}
                  max={20}
                /> */}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Project Task Config
                  </label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('projectTaskConfig', e)
                      }}
                      checked={formik.values.projectTaskConfig}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Smart Idle Enable</label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isSmartIdleEnable', e)
                      }}
                      checked={formik.values.isSmartIdleEnable}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Enable Screenshot BlurEffect
                  </label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        //handleToggle()
                        formik.setFieldValue('isEnableScreenshotBlurEffect', e)
                        //smartIdleEnable ? setSmartIdleEnable(false) : setSmartIdleEnable(true)
                      }}
                      checked={formik.values.isEnableScreenshotBlurEffect}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Enable Live Streaming
                  </label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        // handleToggle()
                        // smartIdleEnable ? setSmartIdleEnable(false) : setSmartIdleEnable(true)
                        formik.setFieldValue('isEnableLiveStreaming', e)
                      }}
                      checked={formik.values.isEnableLiveStreaming}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Enable Live Recording
                  </label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        // handleToggle()
                        // smartIdleEnable ? setSmartIdleEnable(false) : setSmartIdleEnable(true)
                        formik.setFieldValue('isEnableLiveRecording', e)
                      }}
                      checked={formik.values.isEnableLiveRecording}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Private Mode</label>
                  <div className='col-lg-8 fv-row' style={{display: 'flex', alignItems: 'center'}}>
                    <Switch
                      onChange={(e) => {
                        // handleToggle()
                        // smartIdleEnable ? setSmartIdleEnable(false) : setSmartIdleEnable(true)
                        formik.setFieldValue('isEnableManualTracking', e)
                      }}
                      checked={formik.values.isEnableManualTracking}
                    />
                  </div>
                </div>
              </>
            )}

            {alignment === 'OrgAdmin' && (
              <>
                <RowWithSlider
                  fieldName='screenshot_Interval'
                  title='Screenshot Interval (min)'
                  value={formik.values.screenshot_Interval}
                  updateValue={changeFieldValueHandle}
                  min={1}
                  max={30}
                />

                <RowWithSlider
                  fieldName='screenshotBlurEffect'
                  title='Screenshot Blur Effect'
                  value={formik.values.screenshotBlurEffect}
                  updateValue={changeFieldValueHandle}
                  min={0}
                  max={3}
                />

                <RowWithSlider
                  fieldName='idle_Time_Interval'
                  title='Idle Time Interval (min)'
                  value={formik.values.idle_Time_Interval}
                  updateValue={changeFieldValueHandle}
                  min={3}
                  max={30}
                />

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Working Days Per Week(Days)
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Mon'
                          id='flexCheckDefault'
                          checked={formik.values.workingDays.some((day) => day === 'Mon')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Mon</label>
                      </div>

                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Tue'
                          id='flexCheckChecked'
                          checked={formik.values.workingDays.some((day) => day === 'Tue')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Tue</label>
                      </div>

                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Wed'
                          id='kt_check_indeterminate_1'
                          checked={formik.values.workingDays.some((day) => day === 'Wed')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Wed</label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Thu'
                          id='kt_check_indeterminate_1'
                          checked={formik.values.workingDays.some((day) => day === 'Thu')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Thu</label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Fri'
                          id='kt_check_indeterminate_1'
                          checked={formik.values.workingDays.some((day) => day === 'Fri')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Fri</label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Sat'
                          id='kt_check_indeterminate_1'
                          checked={formik.values.workingDays.some((day) => day === 'Sat')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Sat</label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='Sun'
                          id='kt_check_indeterminate_1'
                          checked={formik.values.workingDays.some((day) => day === 'Sun')}
                          onChange={(e) => handleCheckboxChange(e.target.value)}
                        />
                        <label className='form-check-label'>Sun</label>
                      </div>
                    </div>
                    {formik.touched.workingDays && formik.errors.workingDays && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.workingDays}</div>
                      </div>
                    )}
                  </div>
                </div>

                <RowWithSlider
                  fieldName='sessionExpiryDay'
                  title='Session Expiry (Day)'
                  value={formik.values.sessionExpiryDay}
                  updateValue={changeFieldValueHandle}
                  min={1}
                  max={30}
                />

                <RowWithSlider
                  fieldName='idleTimeAlert'
                  title='Idle Time Alert (min)'
                  value={formik.values.idleTimeAlert}
                  updateValue={changeFieldValueHandle}
                  min={1}
                  max={60}
                />

                <RowWithSlider
                  fieldName='suspiciousKeyboardAlert'
                  title='Suspicious Keyboard Alert (min)'
                  value={formik.values.suspiciousKeyboardAlert}
                  updateValue={changeFieldValueHandle}
                  min={1}
                  max={60}
                />

                <RowWithSlider
                  fieldName='captureKeyboardMouseStroke'
                  title='Capture Keyboard / Mouse Stroke (min)'
                  value={formik.values.captureKeyboardMouseStroke}
                  updateValue={changeFieldValueHandle}
                  min={10}
                  max={60}
                />

                <RowWithSlider
                  fieldName='recordingCaptureDurationMinutes'
                  title='Recording Capture Duration (min)'
                  value={formik.values.recordingCaptureDurationMinutes}
                  updateValue={changeFieldValueHandle}
                  min={5}
                  max={120}
                />

                <RowWithSlider
                  fieldName='recordingFramesDurationSeconds'
                  title='Recording Frames Duration (sec)'
                  value={formik.values.recordingFramesDurationSeconds}
                  updateValue={changeFieldValueHandle}
                  min={0}
                  max={5}
                />

                <RowWithSlider
                  fieldName='activeUserDurationMinutes'
                  title='Active User Duration (min)'
                  value={formik.values.activeUserDurationMinutes}
                  updateValue={changeFieldValueHandle}
                  min={10}
                  max={60}
                />

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Time Zone</label>

                  <div className='col-lg-8 fv-row'>
                    <div
                      className='min-w-200px'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search Role'
                    >
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        options={timeZone}
                        value={selectedTimeZone}
                        onBlur={formik.handleBlur}
                        placeholder='Select Time Zone'
                        onChange={(item: any) => {
                          setSelectedTimeZone(item)
                          formik.setFieldValue('timeZoneId', item.value)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                    <div>
                      {formik.touched.timeZoneId && formik.errors.timeZoneId && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.timeZoneId}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Cut Off Time(hh:mm:ss)
                    {/* <img className='info-icon' src="/media/icons/duotune/art/information.svg" alt="" /> */}
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <TimePicker
                      allowEmpty={false}
                      onChange={(Time: any) =>
                        formik.setFieldValue('cutOffTime', dayjs(Time).format('HH:mm:ss'))
                      }
                      value={
                        formik.values.cutOffTime
                          ? moment(formik.values.cutOffTime, 'HH:mm:ss')
                          : undefined
                      }
                      placeholder='Select time'
                    />
                    {formik.touched.cutOffTime && formik.errors.cutOffTime && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.cutOffTime}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Attendance Hours Per Day(hh:mm:ss)
                    {/* <img className='info-icon' src="/media/icons/duotune/art/information.svg" alt="" /> */}
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <TimePicker
                      allowEmpty={false}
                      onChange={(Time: any) =>
                        formik.setFieldValue('noWorkingHours', dayjs(Time).format('HH:mm:ss'))
                      }
                      value={
                        formik.values.noWorkingHours
                          ? moment(formik.values.noWorkingHours, 'HH:mm:ss')
                          : undefined
                      }
                      placeholder='Select time'
                    />
                    {formik.touched.noWorkingHours && formik.errors.noWorkingHours && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.noWorkingHours}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Idle Mail Alert</label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isIdleMailAlert', e)
                      }}
                      checked={formik.values.isIdleMailAlert}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Suspicious Keyboard Mail Alert
                  </label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isSuspiciousKeyboardMailAlert', e)
                      }}
                      checked={formik.values.isSuspiciousKeyboardMailAlert}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Start Stop Mail Alert
                  </label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isStartStopMailAlert', e)
                      }}
                      checked={formik.values.isStartStopMailAlert}
                    />
                  </div>
                </div>
              </>
            )}

            <style>
              {`
                        .rc-time-picker-input{
                          height:3rem;
                          font-weight:700;
                          // width:59.3rem;
                        }

                        .rc-time-picker-panel-input{
                          font-weight:700
                        }

                    `}
            </style>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SystemConfig
